import React, { useState, useEffect } from "react";
import "./Flashcards.css";
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import NewSet from "./NewSet";
import api from "../../services/api";
import TopBar from './TopBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';



const Flashcards = ({ navPaneExtended, toggleNavPane, checkAuth, triggerRefetch }) => {
    const [documents, setDocuments] = useState([]);
    const [flashcardSets, setFlashcardSet] = useState([]);
    const [thumbnails, setThumbnails] = useState({});

    const [renameValue, setRenameValue] = useState({});
    const [workflowRename, setWorkflowRename] = useState({})
    const [deletingWorkflow, setDeleteingWorkflow] = useState({})

    const [itemDelete, setItemDelete] = useState({})



    const [flashcards, setFlashcards] = useState([]);
    const [selectedSet, setSelectedSet] = useState(null);
    const [flippedCards, setFlippedCards] = useState({});
    const [animate, setAnimate] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({});
    const [menuVisible, setMenuVisible] = useState({});
    // const [loading, setLoading] = useState(true);


    const getFlashcards = async () => {
        let response = await api.get("/flashcard-sets");
        console.log('flashcard sets')
        console.log(response.data);
        setFlashcardSet(response.data);
        response.data.forEach((fc) => {
            getThumbnails(fc.flashcard_set_id);
        });

    }

    const toggleMenu = (itemId) => {
        console.log(itemId)
        console.log(menuVisible)
        setMenuVisible((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleRenameChange = (itemId, event) => {
        // Update the input value for the specific workflow
        let fs = flashcardSets.filter(fs => itemId === fs.flashcard_set_id)
        console.log(fs)
        setRenameValue((prevData) => ({ ...prevData, [itemId]: event.target.value }));
    };


    const toggleRename = (fcId) => {
        setMenuVisible(false);

        setRenameValue((prevState) => ({
            ...prevState,
            [fcId]: flashcardSets.find(e => e.flashcard_set_id === fcId).title
        }))
        setWorkflowRename((prevState) => ({
            ...prevState,
            [fcId]: true
        }))
    }

    const deleteItem = (itemId) => {
        console.log(itemId);
        setItemDelete({ ...itemDelete, [itemId]: true })

    }

    const deleteWorkflowCancel = (itemId) => {
        setItemDelete({ ...itemDelete, [itemId]: false });
    }



    const deleteWorkflowConfirm = async (itemId) => {
        setDeleteingWorkflow((prevState) => ({
            ...prevState,
            [itemId]: true
        }))

        let response = await api.post(`/delete-flashcard-set/${itemId}`);
        console.log(response);
        setDeleteingWorkflow((prevState) => ({
            ...prevState,
            [itemId]: false
        }))

        getFlashcards();



    }


    const setWorkflowName = async (fsId) => {

        setWorkflowRename((prevState) => ({
            ...prevState,
            [fsId]: false
        }))

        //  console.log(workflows)

        setFlashcardSet((prevState) =>
            prevState.map((fs) =>
                fs.flashcard_set_id === fsId
                    ? { ...fs, title: renameValue[fsId] } // Update the name if the ID matches
                    : fs // Keep the workflow unchanged if the ID doesn't match
            )
        );

        const response = await api.post(`/rename-flashcard-set/${fsId}`, {}, {
            params: { 'new_name': renameValue[fsId] }
        })


    }


    const getThumbnails = async (fcId) => {
        // Start loading indicator for the specific flashcard set ID
        setLoading((prevLoading) => ({
            ...prevLoading,
            [fcId]: true,
        }));

        let imageUrl = undefined;

        try {
            // Make an API call to fetch the thumbnail
            let response = await api.get(`/flashcard-set-thumbnail/${fcId}`, { responseType: 'blob' });

            // If the API provides a response with a blob, convert it to an image URL
            imageUrl = URL.createObjectURL(response.data);
        } catch (error) {
            // If there's an error (like no thumbnail), set the default image from the public folder
            imageUrl = '/bookset.jpg';  // Ensure this image is present in your public folder
        }

        // Update the thumbnails state with the new image URL (or default image)
        setThumbnails((prevData) => ({
            ...prevData,
            [fcId]: imageUrl,
        }));

        // Stop the loading indicator for the specific flashcard set ID
        setLoading((prevLoading) => ({
            ...prevLoading,
            [fcId]: false,
        }));
    };


    useEffect(() => {
        api.get("/user-documents").then((response) => {
            console.log('documents')
            console.log(response);
            setDocuments(response.data);


        });
        getFlashcards();

    }, []);

    const openNewWorkflowModal = () => {
        dispatch(openModal({
            component: NewSet,
            props: {
                existingDocuments: documents,
                createSetapi: '/create-flashcards',
                title: 'Flashcard',
                callBack: getFlashcards
            }
        }));
    };



    const handleSetClick = async (setId) => {
        // Dummy data for flashcards in the selected set
        api.get(`/flashcard-set/${setId}`).then((response) => {
            console.log('flashcards')
            console.log(response);
            setFlashcards(response.data);
        });

        setSelectedSet(setId);
        setFlippedCards({}); // Reset flipped cards when a new set is selected
        setAnimate(true); // Trigger the animation
    };

    const handleCardFlip = (cardId) => {
        setFlippedCards((prev) => ({
            ...prev,
            [cardId]: !prev[cardId],
        }));
    };

    const handleBackToSets = () => {
        setAnimate(false); // Reverse the animation
        setSelectedSet(null);
        setFlashcards([]);

    };

    return (
        <div className="flc-container">
            <TopBar
                navPaneExtended={navPaneExtended}
                toggleNavPane={toggleNavPane}
                checkAuth={checkAuth}
                title="Flashcards"
                newBtnClick={flashcardSets.length > 0 ? openNewWorkflowModal : undefined}
                triggerRefetch={triggerRefetch} />
            {flashcardSets.length === 0 && <div className="flc-empty">

                <div className="btn feedback" onClick={openNewWorkflowModal}>Create a set</div>

            </div>}

            {flashcardSets.length > 0 && <div className="flc-internal">



                <div
                    className={`flc-content ${animate ? "animate" : ""} ${selectedSet ? "set-selected" : ""
                        }`}
                >
                    <div className="flc-sets">
                        {selectedSet && (
                            <div onClick={handleBackToSets} className="simple-btn">
                                &larr; Back to Sets
                            </div>
                        )}
                        <div className={`flc-sets-grid ${selectedSet ? "single" : ""}`}>
                            {flashcardSets.map((fs) => (
                                <div
                                    key={fs.flashcard_set_id}
                                    className={`flc-flashcard-item ${fs.flashcard_set_id === selectedSet ? "active" : ""}`}
                                    onClick={() => handleSetClick(fs.flashcard_set_id)}
                                    title={fs.title}
                                >

                                    <div className="flc-item-header">
                                        {workflowRename[fs.flashcard_set_id] &&
                                            (<>

                                                <input className='wf-rename'
                                                    value={renameValue[fs.flashcard_set_id]}
                                                    type="text"
                                                    onChange={(e) => { handleRenameChange(fs.flashcard_set_id, e) }}
                                                    onClick={(e) => { e.stopPropagation() }}

                                                />

                                                <FontAwesomeIcon
                                                    className='wf-rename-confirm'
                                                    icon='fa-solid fa-check'
                                                    onClick={(e) => {e.stopPropagation(); setWorkflowName(fs.flashcard_set_id)}}></FontAwesomeIcon>




                                            </>)}
                                        {!workflowRename[fs.flashcard_set_id] && <h3 className="flc-item-title">
                                            {fs.title}
                                        </h3>}



                                        <div className="wf-menu-container" onClick={(e) => e.stopPropagation()}>

                                            <FontAwesomeIcon icon='fa-solid fa-ellipsis-vertical'
                                                className='wf-menu-btn'
                                                onClick={(e) => {
                                                    toggleMenu(fs.flashcard_set_id)
                                                }}>
                                            </FontAwesomeIcon>



                                            <CSSTransition
                                                in={menuVisible[fs.flashcard_set_id]}
                                                timeout={300}
                                                classNames="menu-transition"
                                                unmountOnExit
                                            >
                                                <div className="item-menu" >
                                                    <ul>
                                                        <li onClick={(e) => { e.stopPropagation(); toggleRename(fs.flashcard_set_id) }}>
                                                            Rename
                                                        </li>
                                                        {!itemDelete[fs.flashcard_set_id] && <li onClick={() => deleteItem(fs.flashcard_set_id)}>Delete</li>}
                                                        {itemDelete[fs.flashcard_set_id] && <li className='confirm-delete'>Sure?
                                                            {!deletingWorkflow[fs.flashcard_set_id] && <div className="wf-delete-controls">
                                                                <FontAwesomeIcon
                                                                    className='wf-delete-control wf-delete-confirm'
                                                                    icon='fa-solid fa-check'
                                                                    onClick={() => deleteWorkflowConfirm(fs.flashcard_set_id,)}></FontAwesomeIcon>
                                                                <FontAwesomeIcon
                                                                    className='wf-delete-control wf-delete-cancel'
                                                                    icon='fa-solid fa-close'
                                                                    onClick={() => deleteWorkflowCancel(fs.flashcard_set_id,)}></FontAwesomeIcon>
                                                            </div>}
                                                            {deletingWorkflow[fs.flashcard_set_id] && <div className="loader"></div>}
                                                        </li>}
                                                    </ul>
                                                </div>

                                            </CSSTransition>
                                        </div>
                                    </div>


                                    <div className="flc-item-display">
                                        {loading[fs.flashcard_set_id] ? (
                                            <div className="loader"></div>  // Display loader while fetching the thumbnail
                                        ) : (
                                            <img
                                                src={thumbnails[fs.flashcard_set_id] || '/bookset.jpg'}  // Fallback in case the thumbnail is missing
                                                alt={fs.title}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {selectedSet && (
                        <div className="flc-flashcards">
                            {flashcards.map((flashcard) => (
                                <div
                                    key={flashcard.flashcard_id}
                                    className={`flashcard ${flippedCards[flashcard.flashcard_id] ? "flipped" : ""
                                        }`}
                                    onClick={() => handleCardFlip(flashcard.flashcard_id)}
                                >
                                    <div className="flashcard-inner">
                                        <div className="flashcard-front">
                                            {flashcard.front_text}
                                        </div>
                                        <div className="flashcard-back">
                                            {flashcard.back_text}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>}
        </div>
    );
};

export default Flashcards;
